@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,body {
    font-family: "Poppins", sans-serif;
  }
.reviews-div{
    width: 80%;
    max-width: 900px;
    margin: 0 auto;
    margin-bottom: 7rem;
    height: fit-content;
}

.reviews-title{
 text-align: left;
 margin-top: 3rem;
 margin-bottom: 1rem;
 font-size: 2rem;
}

.individual-review{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 2rem;
}

/* img{
    border: 1px solid black;
} */

.stars{
    display: flex;
    flex-direction: row;
    margin-bottom: 4rem;    
}

.star{
    width: 4rem;
    min-width: 40px;
    max-width: 90px;
}


.star-review{
    width: 2rem;
    /* min-width: 40px;
    max-width: 90px; */
}
