
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,body {
    font-family: "Poppins", sans-serif;
  }

@media only screen and (max-width: 802px) {
  .nav-container>li{
    display: none;
  }

  .logo{
    margin-left: 10px;
  }

}

@media only screen and (min-width: 802px) {
  .hamburger>div{
    display: none;
  }

  .menu-items{
    display: none;
  }

}


#toggle{
  display: none;
}

.menu-items{
  font-size: 1.5rem;
  list-style: none;
  margin: 0 auto;
  padding-bottom: 10rem;
  text-align: center;
}

.menu-items>li{
  margin-top: 30px;
}


#toggle:checked + .hamburger .top-bun {
  transform: rotate(-45deg);
  margin-top: 25px;
}
#toggle:checked + .hamburger .bottom-bun {
  opacity: 0;
  transform: rotate(45deg);
}
#toggle:checked + .hamburger .meat {
  transform: rotate(45deg);
  margin-top: -7px;
}

#toggle:checked + .hamburger + .nav {
  top: 0;
  transform: scale(1);
}

.hamburger {
  position: absolute;
  top: 4rem;
  right: 7%;
  margin-left: -2em;
  margin-top: -45px;
  width: 2em;
  height: 45px;
  z-index: 5;
}

.hamburger div {
  position: relative;
  width: 3em;
  height: 7px;
  border-radius: 3px;
  background-color: black;
  margin-top: 8px;
  transition: all 0.3s ease-in-out;
}

.navbar{
  width: 100%;
  /* border: 1px solid black; */
  position: absolute;
  top:0;
}


.nav-container{
  list-style: none;
  font-size: 1.45rem;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: space-between;
  gap:10px;
  width: 900px;
}

.nav-container>li{
  margin-top: 40px;
  /* border: 1px solid red; */
  height: fit-content;
  padding-bottom: 5px;
}

.nav-container>li:hover{
  transform: translateY(-3px);
  border-bottom: 2px solid black;

}

.logo{
  width: 110px;
}
li>a{
  text-decoration: none ;
  color: black;
  cursor: pointer;
  padding-bottom: 6px;
}


 