@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,body {
    font-family: "Poppins", sans-serif;
  }
.schedule{
   width: 50%;
   justify-content: space-between;
   display: flex;
   flex-direction: row;
}

.contact-div{
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 900px;
    margin: 0 auto;
    gap:3rem;
    margin-top: 3rem;
    padding-bottom:4rem;
}

.day-div{
    display: flex;
    flex-direction: row;
}

.titles{
display: flex;


}

.contact{
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.p-day{
    font-size: 0.9rem;
    text-align: right;
}



.week1,.week2{
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

@media only screen and (max-width: 600px) {
    .contact-div{
      flex-direction: column;
    }

    .schedule{
        width: 80%;
    }
}

@media only screen and (max-width: 650px) {
    .week2{
        display: none;
    }
}