@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,body {
    font-family: "Poppins", sans-serif;
  }

.booking-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;
    gap: 1rem;
    height:calc(76vh - 220px);    
    overflow-y: auto; /* Enable vertical scrolling when content overflows */
    /* border: 1px solid black; */
    /* border:1px solid red; */
    flex-shrink: 1;
  
}

.name-fields {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.name-fields>div {
    flex-basis: 48%;
    /* Adjust the width as needed */
}

label {
    font-weight: bold;
}

input::placeholder,
textarea::placeholder {
    font-size: 1.1rem;
    /* Adjust the font size as needed */
    color: grey;
    /* Change the color to your preference */
    transform: translate(0.3rem, -0.5rem);
    /* Combine transformations */

    transition: all 0.3s ease;
    /* Add a smooth transition effect */
}

input:focus::placeholder,
textarea:focus::placeholder {
    transform: translateY(-1.2rem);
    /* Adjust the position when focused */
    font-size: 0.9rem;
    /* Adjust the font size when focused */
    color: #d4af37;
    /* Change the color when focused */
}


input,
textarea {
    width: 100%;
    margin: 4px 0;
    border: none;
    border-bottom: 2px solid rgb(189, 186, 186);
    border-top-left-radius: 6px;
    border-top-right-radius:6px ;
    padding-top: 1.3rem;
    padding-bottom: 5px;
    background-color: rgb(243, 243, 243);
    overflow: hidden;
    transition: border-color 0.1s ease;
    padding-left: 0.4rem;
    height:50px;
    /* Add transition for border color */
}

/* Style for focused input fields */
input:focus,
textarea:focus {
    outline: none;
    border-bottom: 2px solid #d4af37;
    /* Remove the default focus outline if desired */
}

.lash-set-info{
    border: 1px solid rgb(221, 219, 219);
    width: 99.7%;
    display: flex;
    flex-direction: column;
    height:fit-content;
    margin: 0 auto;
}

.details-p{
    margin-top: 5%;
    font-weight: 600;
    margin-left: 1rem;
    font-size: 1.1rem;
}

.details-p-booking{
    border-bottom: 1px solid  rgb(221, 219, 219);
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    font-size: 0.95rem;
    color: rgb(121, 121, 121);
    padding-bottom: 4px;
}

.details-p-booking-total{
    margin-left: 0.6rem;
    margin-right: 1rem;
    font-weight: 600;    
    font-size: 1.1rem;
    display: flex;
    justify-content: space-between;

}

.details-p-booking-name{
    margin-left: 0.6rem;
    margin-right: 0.5rem;
    border-bottom: 1px solid  rgb(221, 219, 219);
    font-size: 1.1rem;
    padding-bottom: 4px;
}


.confirm-button{
    position: absolute;
    bottom: 10px;
    margin: 0 auto;
    width: 95%;
    height: 3.4rem;
    border: none;
    border-radius: 8px;
    background-color: #d4af37;
    color: white;
    font-weight: 600;
    font-size: 1.1rem;
    opacity: 2;
}
.confirm-button:hover{
    opacity: 0.8;

}

.form-div{
    gap:10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 2rem;
}

.confirm-button{
    background-color: #FFB6C1;
    color: black;
}

