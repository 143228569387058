div.container {
    max-width: 600px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    text-align: left;
  }
  h1 {
    color: #333;
    padding-bottom: 5px;
    border-bottom: 4px solid #d4af37;
  }



h3{
    text-align: left;
}


.date-div, .price-div, .duration-div{
    display: flex;
    flex-direction: column;
    text-align: center;
    /* border:1px solid grey; */
    border-radius: 30px;
    width: 50%;
    background-color: #d4af37 ;
    height: 120px;
    min-width: 100px;

}

.ddp{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
}

.contact-details-p{
    font-weight: bold;
    border-bottom: 2px solid #d4af37;
    width: fit-content;
    padding-right: 30px;
}