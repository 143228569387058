.offers-div {
    margin: 0 auto;
    margin-top: 4rem;
    width: 90%;
    max-width: 900px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f8f8f8;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10rem;
    height: 60vh;
}

.offers-div h2 {
    color: #333;
    font-size: 24px;
    font-size: 1.7rem;
    margin-bottom: 20px;
}

.offers-div p {
    color: #555;
    font-size: 16px;
    margin-bottom: 20px;
    font-size: 1.1rem;
}

.offers-div p:last-child {
    margin-bottom: 0; /* Remove bottom margin for the last paragraph */
}

/* Style for the note at the end */
.offers-div .note {
    color: #777;
    font-size: 14px;
    margin-top: 20px;
}
