@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,body {
    font-family: "Poppins", sans-serif;
  }
.service-info{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid grey;
    border-left: 1px solid grey;
    margin-top: 0.6rem;
    padding-left: 4px;
    font-size: 103%;
}

.services-div{
    max-width: 900px;
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.offers-services-div{
    margin:0 auto;

    max-width: 900px;
}

