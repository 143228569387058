@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,body {
    font-family: "Poppins", sans-serif;
  }
.home-div{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
}
.lashes-logo{
    width: 27%;
}

.homephoto{
    margin-top: 20px;
    width: 100vw;
    max-width: 900px;
}

.home{
    /* border: 1px solid black; */
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
}

.logo-div{
    width: 20rem;
    border: 1px solid black;
    background-color: black;
}

.gift{
    width: 4rem;
}

.offers-p{
    font-size: 1.2rem;
    width: 14rem;
    height: 3rem;
    border-radius: 10px;
    border: none;
    background-color:#FFB6C1;
    color: black;
    font-weight: 450;
}

@media only screen and (min-width: 802px) {
    .gift{
        display: none;
    }

    .offers-p{
        display: none;
    }
}