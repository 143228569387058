.dates-admin{
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
}

.slots-admin{
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: 35%;
    flex-wrap:wrap;
    text-align: center;
    gap:5px;
}

.slot-admin{
    background-color: green;
    width: 100px;
    font-weight: bold;
}

.slot-admin.unavailable{
    background-color: rgb(204, 85, 85);
}

@media only screen and (max-width: 700px) {
    .slots-admin{
        width: 90%;
    }
}

.bookings-admin {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    background-color: #f5f5f5;
    /* Add more styling as needed */
}

.booking-info {
    /* Style for the left column with basic info */
    float: left;
    width: 50%;
    /* Add more styling as needed */
}

.booking-details {
    /* Style for the right column with additional details */
    float: left;
    width: 50%;
    /* Add more styling as needed */
}

/* Clear floats to prevent layout issues */
.bookings-admin::after {
    content: '';
    display: table;
    clear: both;
}
