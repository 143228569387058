@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

html,body {
    font-family: "Poppins", sans-serif;
  }
.modal-form {
    position: absolute;
    width: 500px;
    /* border: 2px solid red; */
    bottom: 2rem;
    right: 3%;
    height: 85%;
    border-radius: 10px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15), inset -2px 0px 0px #fff;
    background-color: white;
}




.back-to-sets {
    position: absolute;
    top: 0.1rem;
    left: 1rem;
    border: none;
    /* background-color: #d4af37; */
    width: 25rem;
    padding: 0.8rem;
    border-radius: 10px;
    color: #FFB6C1;
    font-weight: 900;
    font-size: 30px;
}

.back-to-sets:hover {
    cursor: pointer;
}


.booking-variants {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5%;
    padding-top: 0.8rem;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1); /* Horizontal offset, Vertical offset, Blur radius, Color */
    padding-bottom: 0.6rem;
    /* background-color: rgb(131, 136, 141); */
}

.booking-variants:hover {
    cursor: pointer;
}

/* Add this CSS to your stylesheet */
.round-checkbox {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #000;
    /* Border color for the checkbox */
    cursor: pointer;
    display: inline-block;
    margin-right: 10px;
    /* Adjust margin as needed */
    /* margin-top: 0.1rem; */
}

.round-checkbox.selected {
    background-color: #FFB6C1;
    /* Background color when selected */
    /* border-color: #000; */
    /* Border color when selected */
    border:none;
    color: #fff;
    /* Text color when selected */
}

.box-name-and-duration{
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.service-name {
    width: 12rem;
    text-align: left;
    font-weight: 600;
  }

@media only screen and (max-width: 500px) {
    .modal-form {
        width: 90%;
        /* height: 90%; */
    }
    .service-name {
    width: 10rem;
    }

    .modal-close-button {
        top: 10%;
        background-color: black;
    }

    .box-name-and-duration{
        display: flex;
        flex-direction: column;
        gap:0rem;
    }

    .duration-p{
        margin-left: 2.5rem;
    }

    .round-checkbox{
        margin-top: 0rem;

    }
}

.box-and-name {
    display: flex;
    flex-direction: row;
}


    
.choose-time-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    width: 100%;
    /* border: 1px solid black; */
    background-color: black;
    height: 5rem;
    padding-top: 1rem;
    z-index: 1;
}

.choose-time-button {
    /* position: absolute;
    bottom: 1rem; */
    margin-right: 10px;
    margin-bottom: 10px;
    right: 4%;
    border: none;
    background-color: #FFB6C1;
    padding: 0.8rem;
    border-radius: 10px;
    color: black;
    font-weight: 500;
  }

.modal-title{
    position: relative;
    /* border: 1px solid black; */
    background-color: white;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Add a box shadow to the bottom when focused */
    padding-bottom: 1rem;
}

.display-services{
    overflow-y: auto;
    height: calc(101% - 180px);
}

.description-name{
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
}

.description{
    margin-left: 2.4rem;
    color: #666565;
}